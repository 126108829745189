<template>
  <div class="file-wrapper">
    <input
      ref="input"
      type="file"
      multiple="multiple"
      accept="image/*"
      hidden="hidden"
      @change="handleFile"
    />
    <button type="button" class="files-load__button button" @click="openInput" :style="getStyle">
      {{ 'Загрузить скриншоты' }}
    </button>
    <div class="files-status">
      <span class="files-status__title" v-if="files.length"
        ><span class="files-status__files fl-set--r">{{ getFilename.slice(0, 15) }}...</span></span
      >
      <span class="files-status__remove no-file-chosen" v-if="!files.length && !loaded"
        >Файл не выбран</span
      >
      <span class="files-status__remove no-file-chosen"> Выбрано файлов: {{ loaded }} </span>
    </div>
  </div>
</template>

<script>
import literal from '@main/func/literal';

export default {
  props: ['value', 'loading', 'loaded'],
  data: () => ({
    files: [],
  }),
  computed: {
    getFilename() {
      return this.files[this.files.length - 1].name;
    },
    getStyle() {
      if (!this.loading) return;
      return {
        background: `linear-gradient(to right,
                                       rgb(56, 151, 240) ${this.loading}%, 
                                       grey ${this.loading}% ${100 - this.loading}%)`,
      };
    },
  },
  methods: {
    openInput() {
      this.$refs.input.click();
    },
    handleFile(e) {
      this.files.push(e.target.files[0]);
      this.$emit('input', e.target.files);
    },
  },
};
</script>

<style scoped>
.file-wrapper {
  width: 100%;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.files-load__button {
  width: 168px !important;
  min-width: 168px !important;
  line-height: 16px;
  height: 36px;
  font-size: 14px;
  border-radius: 5px;
  background-color: #3897f0;
  border: none;
  padding: 10px;
  outline: none;
  cursor: pointer;
  white-space: nowrap !important;
  color: #fff;
}

.files-status {
  position: absolute;
  right: 0;
  width: calc(100% - 178px);
}

.files-status__title {
  display: block;
  height: 18px !important;
}

.files-status__remove {
  color: #b4bdc1;
  text-align: left;
}
</style>
