<template>
  <modal title="Примеры" :show="show" @hide="() => $emit('hide')">
    <div class="wrap-popup__inner medium-popup__inner">
      <div class="big-popup__body screenshots__inner scroll">
        <img src="@main/assets/stat-examples/1.jpg" />
        <img src="@main/assets/stat-examples/2.jpg" />
        <img src="@main/assets/stat-examples/3.jpg" />
        <img src="@main/assets/stat-examples/4.jpg" />
        <img src="@main/assets/stat-examples/6.jpg" />
        <img src="@main/assets/stat-examples/7.jpg" />
        <img src="@main/assets/stat-examples/8.jpg" />
        <img src="@main/assets/stat-examples/9.jpg" />
        <img src="@main/assets/stat-examples/10.jpg" />
        <img src="@main/assets/stat-examples/11.jpg" />
        <img src="@main/assets/stat-examples/12.jpg" />
        <img src="@main/assets/stat-examples/13.jpg" />
        <img src="@main/assets/stat-examples/14.jpg" />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.big-popup__body .screenshots__inner .scroll {
  overflow: auto !important;
}
</style>
