<template>
  <div class="row">
    <ad-type-button
      @input="$emit('input', $event)"
      :active="value === i"
      :key="i"
      :val="i"
      :title="key"
      v-for="(key, i) in options"
    />
  </div>
</template>

<script>
import AdTypeButton from './ReviewAdTypeButton.vue';

export default {
  components: {
    AdTypeButton,
  },
  props: ['value', 'options'],
  data() {
    return {};
  },
};
</script>

<style scoped>
.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
