<template>
  <div class="mb-4">
    <MultiStepWindow
      :steps="steps"
      :currentStep="currentStep"
      contentClass="inner-container"
      class="form form--1"
      :class="{
        'form--blogger': reviewData.customer_kind === 'blogger',
        'form--commerce': reviewData.customer_kind === 'shop',
        'd-none': complete,
      }"
    >
      <template #upperContent>
        <div class="inner-container">
          <div class="refuse-reason" v-if="review">
            <p style="font-weight: 500">Причина отклонения:</p>
            <ul v-if="review.refuse_reasons.length" style="padding-left: 20px">
              <li
                v-for="(reason, idx) in review.refuse_reasons"
                :id="`${idx}-reason`"
                :key="`${reason}-awdjuh87h2hd`"
                :title="refuseReasons.filter((val) => val.id === reason)[0].text"
                style="cursor: pointer; list-style: disc; white-space: pre-line"
              >
                {{ refuseReasons.filter((val) => val.id === reason)[0].title }}
              </li>
            </ul>
            <span v-else> не указана</span>
          </div>
        </div>
      </template>
      <template #contentHeader>
        <div class="title-part">
          <h2 class="title-part__title">
            <template v-if="!review"> Оставить отзыв на блогера </template>
            <template v-else-if="review.left_resent_tries > 0">
              Редактирование отзыва №{{ review.id }}. Осталось {{ review.left_resent_tries }} из 2
              попыток
            </template>
            <template v-else> У вас не осталось попыток редактирования отзыва! </template>
          </h2>
          <span class="title-part__step subtitle gray-text">Шаг {{ currentStep }} из 2</span>
        </div>
      </template>
      <template #step1>
        <div class="form-part">
          <h3 class="form-part__title">
            Вы блогер или у вас коммерческий аккаунт (например, магазин)?
          </h3>
          <b-button-group class="w-100">
            <b-button
              class="w-50"
              :variant="reviewData.customer_kind === 'blogger' ? 'primary' : 'outline-default'"
              @click="reviewData.customer_kind = 'blogger'"
            >
              Блогер
            </b-button>
            <b-button
              class="w-50"
              :variant="reviewData.customer_kind === 'shop' ? 'primary' : 'outline-default'"
              @click="reviewData.customer_kind = 'shop'"
            >
              Коммерция
            </b-button>
          </b-button-group>
        </div>
        <!--div
          class="form-part ad-mode"
          :class="{ 'ad-mode--chosen': review ? review.customer_kind : false }"
        >
          <h3 class="form-part__title">
            Вы блогер или у вас коммерческий аккаунт (например, магазин)?
          </h3>
          <div class="row">
            <button class="button" @click="reviewData.customer_kind = 'blogger'">Блогер</button>
            <button class="button" @click="reviewData.customer_kind = 'shop'">Коммерция</button>
          </div>
          <span
            class="warning"
            v-if="!$v.reviewData.customer_kind.required && $v.reviewData.customer_kind.$error"
          >
            Выберите вариант, чтобы продолжить
          </span>
        </!div-->
        <div class="form-part">
          <h3 class="form-part__title">Ссылка на мой аккаунт</h3>
          <instaname-input
            :class="{ error: $v.reviewData.customer.$error }"
            v-model="reviewData.customer"
            @blur="$v.reviewData.customer.$touch()"
          />
          <span
            class="warning"
            v-if="!$v.reviewData.customer.required && $v.reviewData.customer.$error"
          >
            Заполните это поле, чтобы продолжить
          </span>
          <span class="warning warning--equals">
            Мой аккаунт и аккаунт блогера, у которого была реклама, должны быть разными
          </span>
        </div>
        <div class="form-part">
          <h3 class="form-part__title">Реклама была у блогера</h3>
          <instaname-input
            :class="{ error: $v.reviewData.advertiser.$error }"
            v-model="reviewData.advertiser"
            @blur="$v.reviewData.advertiser.$touch()"
          />
          <span
            class="warning"
            v-if="!$v.reviewData.advertiser.required && $v.reviewData.advertiser.$error"
          >
            Заполните это поле, чтобы продолжить
          </span>
          <p v-if="reviewData.customer && reviewData.advertiser" class="fs-12 gray-text mt-1">
            Проверьте ники блогеров. При ошибочных ссылках отзыв не будет опубликован.
          </p>
        </div>
        <div class="form-part" id="calendar">
          <h3 class="form-part__title">Дата выхода рекламы</h3>
          <date-picker
            :model-config="modelConfig"
            mode="date"
            timezonE="Europe/Moscow"
            :locale="'ru'"
            :max-date="pastDay"
            :popover="{ visibility: 'click' }"
            v-model="reviewData.date"
            @blur="$v.reviewData.date.$touch"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <b-input
                class="h-36"
                :class="{ error: $v.reviewData.date.$error }"
                placeholder="ДД.ММ.ГГ"
                :value="inputValue"
                v-on="inputEvents"
                @blur="$v.reviewData.date.$touch"
              /> </template
          ></date-picker>
          <span class="warning" v-if="!$v.reviewData.date.required && $v.reviewData.date.$error"
            >Заполните это поле, чтобы продолжить.</span
          >
          <!-- <span class="warning">Отзыв нельзя оставить в будущем времени</span> -->
        </div>
        <div class="form-part worth only-commerce">
          <h3 class="form-part__title">Реклама окупилась</h3>
          <div class="row" name="paid_off">
            <b-button-group class="w-100">
              <b-button
                class="w-50"
                :variant="reviewData.paid_off ? 'primary' : 'outline-default'"
                @click="reviewData.paid_off = true"
              >
                Да
              </b-button>
              <b-button
                class="w-50"
                :variant="!reviewData.paid_off ? 'primary' : 'outline-default'"
                @click="reviewData.paid_off = false"
              >
                Нет
              </b-button>
            </b-button-group>
          </div>
          <span class="warning" v-if="false">Выберите вариант, чтобы продолжить</span>
        </div>
        <div class="form-part price">
          <h3 class="form-part__title">Цена за рекламу в ₽</h3>
          <div class="row form-part">
            <input
              class="input"
              type="number"
              min="0"
              step="1"
              @keypress="
                (event) => {
                  return event.charCode == 8 || event.charCode == 0
                    ? null
                    : event.charCode >= 48 && event.charCode <= 57;
                }
              "
              pattern="\d+"
              :class="{ error: $v.reviewData.price.$error }"
              v-model="reviewData.price"
              @blur="$v.reviewData.price.$touch()"
            />
            <div class="only-blogger">
              <div class="boxes">
                <b-form-checkbox
                  v-model="reviewData.price_meta"
                  :unchecked-value="null"
                  value="together"
                  type="radio"
                >
                  Взаимопиар
                </b-form-checkbox>
                <b-form-checkbox
                  disabled
                  :checked="reviewData.price && reviewData.price_meta === 'together'"
                >
                  ВП + доплата
                </b-form-checkbox>
              </div>
            </div>
            <div class="only-commerce">
              <div class="boxes">
                <b-form-checkbox
                  v-model="reviewData.price_meta"
                  :unchecked-value="null"
                  value="barter"
                  type="radio"
                >
                  Бартер
                </b-form-checkbox>
                <b-form-checkbox
                  disabled
                  :checked="reviewData.price && reviewData.price_meta === 'barter'"
                >
                  Бартер + доплата
                </b-form-checkbox>
              </div>
            </div>
            <span
              class="warning"
              v-if="$v.reviewData.price.$anyError || $v.reviewData.price_meta.$anyError"
              >Заполните это поле или выберите чекбокс, чтобы продолжить</span
            >
          </div>
        </div>
        <div class="form-part">
          <h3 class="form-part__title">Приход подписчиков</h3>
          <input
            :class="{ error: $v.reviewData.arrival.$error }"
            v-model="reviewData.arrival"
            type="number"
            class="input"
            @blur="$v.reviewData.arrival.$touch()"
          />
          <span
            class="warning"
            v-if="!$v.reviewData.arrival.required && $v.reviewData.arrival.$error"
            >Заполните это поле, чтобы продолжить</span
          >
        </div>
      </template>
      <template #step2>
        <div class="form-part rate" id="rate">
          <h3 class="form-part__title">
            Оцените рекламу <i class="custom-star icon-star">&#xe800;</i>
            <span id="rating"> {{ reviewData.rate }}</span>
          </h3>
          <div class="row">
            <div class="stripe"></div>
            <div
              class="stripe stripe--dark"
              :style="{ width: (100 / 9) * (reviewData.rate - 1) + '%' }"
            ></div>
            <input
              style="margin-top: 2px"
              type="range"
              id="level"
              name="rate"
              min="1"
              max="10"
              v-model="reviewData.rate"
            />
          </div>
          <span class="warning warning--low_rate" v-if="reviewData.rate < 6">
            Если вы пишите негативный текст для отзыва, то каждое утверждение в нем должно быть
            подтверждено скриншотом. Без подтверждения модераторы не пропустят ваш отзыв или
            опубликуют его без текста
          </span>
        </div>
        <div class="form-part">
          <h3 class="form-part__title">Формат рекламы</h3>
          <ad-type-select
            v-model="reviewData.ad_type"
            :options="{
              '1': 'Сторис',
              '2': 'Фото-пост',
              '3': 'Видео-пост',
              '4': 'Сторис + пост',
            }"
          />
          <span
            class="warning"
            v-if="!$v.reviewData.ad_type.required && $v.reviewData.ad_type.$error"
            >Выберите вариант, чтобы продолжить</span
          >
        </div>
        <div class="form-part only-blogger">
          <h3 class="form-part__title">Темы аккаунта заказчика</h3>
          <multiselect
            :class="{ error: $v.reviewData.customer_tags.$anyError }"
            :options="tags"
            placeholder="Выберите темы (максимум 3)"
            select-label=""
            deselectLabel=""
            selectedLabel="Выбрано"
            label="name"
            track-by="id"
            searchable="searchable"
            :multiple="true"
            :max="3"
            v-model="reviewData.customer_tags"
          >
            <span slot="noResult">Тема не найдена</span>
            <template slot="tagPlaceholder">Выберите тему</template>
          </multiselect>
          <span
            class="warning"
            v-if="!$v.reviewData.customer_tags.required && $v.reviewData.customer_tags.$error"
            >Выберите одну или несколько тем из списка, чтобы продолжить</span
          >
        </div>
        <div class="form-part only-commerce">
          <h3 class="form-part__title">Что вы рекламировали</h3>
          <input
            placeholder="Название товара/услуги"
            type="text"
            class="input"
            v-model="reviewData.item"
          />
          <span class="warning" v-if="!$v.reviewData.item.required && $v.reviewData.item.$error"
            >Заполните это поле, чтобы продолжить</span
          >
        </div>
        <div class="form-part upload">
          <h3 class="form-part__title">
            Скриншоты переходов или отметок вашего аккаунта
            <a target="_blank" class="link d-inline-block" @click="() => (showStatExModal = true)"
              >пример</a
            >
          </h3>
          <div class="help-text">
            <p>
              Для сторис: видны охваты сторис, клики на ник и другая статистика (нельзя скрывать
              любую часть скриншота)
            </p>
            <p>
              Для постов: скриншоты с отметкой вашего блога в посте (ник в тексте) и обязательно
              скриншот с охватом этой публикации.
            </p>
          </div>
          <div class="screenshots-list" v-if="reviewData.screenshots">
            <div
              class="screenshot"
              :key="screenshot.id"
              v-for="(screenshot, i) in reviewData.screenshots"
            >
              <a :href="screenshot.image" target="_blank">
                <img :src="screenshot.image" alt="" />
              </a>
              <div class="screenshot-text">
                <p>{{ screenshot.filename }}</p>
                <p class="screenshot-remove-btn" @click="reviewData.screenshots.splice(i, 1)">
                  Удалить
                </p>
              </div>
            </div>
          </div>
          <div class="files-list">
            <file-input
              @input="handleFile"
              :loading="fileLoading"
              :loaded="reviewData.screenshots.length"
            />
          </div>
          <span
            class="warning"
            v-if="!$v.reviewData.screenshots.required && $v.reviewData.screenshots.$error"
            >Загрузите один или несколько скриншотов, чтобы продолжить</span
          >
        </div>
        <div class="form-part">
          <h3 class="form-part__title" id="reach_title">
            Охват первой истории с вашей рекламой
            <a
              target="_blank"
              class="link d-inline-block"
              @click="() => (showStatCoverageExModal = true)"
              >пример</a
            >
          </h3>
          <input v-model="reviewData.largest_reach" type="number" class="input" />
        </div>
        <div class="form-part">
          <h3 class="form-part__title">
            Текст отзыва <span class="gray-text">- можно не писать</span>
          </h3>
          <textarea
            type="text"
            class="input textarea"
            maxlength="300"
            rows="4"
            v-model="reviewData.text"
          ></textarea>
        </div>
        <div class="warning__self-review">
          Не пишите отзывы сами на себя — наши модераторы это быстро определят и забанят нарушителя.
        </div>
        <div class="form-part">
          <b-form-checkbox v-model="reviewData.agree">
            <span style="display: contents" class="option__title"
              >Я соглашаюсь с
              <a target="_blank" style="display: contents" href="/policy" class="link"
                >Пользовательским соглашением</a
              ></span
            >
          </b-form-checkbox>
          <span class="warning" v-if="!$v.reviewData.agree.accepted && $v.reviewData.agree.$error"
            >Выберите этот чекбокс, чтобы продолжить</span
          >
        </div>
      </template>
      <template #footer>
        <div class="bottom-part pb-2">
          <div class="inner-container">
            <button class="bottom-part__button button" @click="reset" v-if="currentStep === 1">
              Сброс
            </button>
            <b-button
              variant="outline-default"
              class="bottom-part__button button"
              @click="goLeft"
              v-else
              >Назад</b-button
            >
            <b-button
              variant="primary"
              class="bottom-part__button button"
              :disabled="loading || (review && review.left_resent_tries < 1)"
              @click="nextStep"
            >
              {{ loading ? 'Обработка...' : 'Далее' }}
            </b-button>
          </div>
          <div class="d-flex justify-content-center my-1 mb-2" style="padding: 0px 57px">
            <b-button
              style="
                line-break: anywhere !important;
                line-break: wrap !important;
                white-space: pre-wrap !important;
                max-width: 400px !important;
              "
              class="text-center d-block"
              target="_blank"
              href="https://tracker.easyprbot.com/bug-form"
              variant="link"
              >Нашли ошибку или не отправлется отзыв? Заполните эту форму</b-button
            >
          </div>
        </div>
      </template>
    </MultiStepWindow>
    <template v-if="complete">
      <div class="page__popup flex w-sm-100" id="success-popup">
        <!--div class="review__success-pad">
          <div class="review--succes__content">
            <img src="../../assets/done_icon.png" alt="Успешно" class="form--3__image" />
            <h2 class="form__message">Отзыв отправлен на модерацию</h2>
            <b-button
              variant="outline-default"
              @click="reset"
              class="form--3__button button__success"
              >Оставить ещё один отзыв</b-button
            >
          </div>
        </!--div-->
        <review-success-ad @reset="reset" />
        <tracker-ad class="w-sm-100" />
      </div>
    </template>
    <stat-coverage-examples-modal
      :show="showStatCoverageExModal"
      @hide="() => (showStatCoverageExModal = false)"
    />
    <StatExamplesModal :show="showStatExModal" @hide="() => (showStatExModal = false)" />
  </div>
</template>

<script>
import '../../styles/let_review.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { mapState, mapMutations } from 'vuex';
import { required, minLength, between } from 'vuelidate/lib/validators';
import instagram from '@main/api/instagram';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import ReviewSuccessAd from '@main/components/ad/ReviewSuccessAd.vue';
import MultiStepWindow from '@main/components/reusable/windows/MultiStepWindow.vue';
import StatExamplesModal from '@main/components/reusable/modals/StatExamplesModal.vue';
import StatCoverageExamplesModal from '@main/components/reusable/modals/StatCoverageExamplesModal.vue';
import apiService from '../../api/index';
import FileInput from './FileInput.vue';
import InstanameInput from './InstanameInput.vue';
import AdTypeSelect from './ReviewAdTypeSelect.vue';
import TrackerAd from '../ad/TrackerAd.vue';
import reviewsApi from '../../api/reviews';

export default {
  components: {
    DatePicker,
    InstanameInput,
    Multiselect,
    AdTypeSelect,
    FileInput,
    TrackerAd,
    StatExamplesModal,
    StatCoverageExamplesModal,
    MultiStepWindow,
    ReviewSuccessAd,
  },
  props: ['id', 'advertiser', 'customer', 'mutual', 'date', 'coverage'],
  computed: {
    ...mapState('reviewsModeration', ['refuseReasons']),
    ...mapState(['tags']),
    screenLink() {
      return `/reviews/screenshots-example/`;
    },
    pastDay() {
      return new Date(moment().subtract(1, 'days'));
    },
  },
  data: () => ({
    modelConfig: {
      type: 'string',
      mask: 'YYYY-MM-DD', // Uses 'iso' if missing
    },
    complete: false,
    showStatCoverageExModal: false,
    showStatExModal: false,
    review: null,
    fileLoading: null,
    steps: 2,
    currentStep: 1,
    loading: false,
    reviewData: {
      customer: null,
      advertiser: null,
      price: null,
      price_meta: null,
      arrival: null,
      paid_off: true,
      date: new Date(moment().subtract(1, 'days')),
      customer_kind: 'blogger',
      rate: 10,
      customer_tags: [],
      item: null,
      agree: false,
      ad_type: null,
      text: '',
      screenshots: [],
      largest_reach: null,
    },
  }),
  methods: {
    ...mapMutations('notifications', ['showMessage']),
    goLeft() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    checkFloat(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      // eslint-disable-next-line yoda
      if (48 <= charCode <= 57) {
        return true;
      }
      evt.preventDefault();
    },
    async nextStep() {
      console.log(this.currentStep);
      this.$v[`step${this.currentStep}Group`].$touch();
      console.log(this.$v[`step${this.currentStep}Group`].$error);
      if (!this.$v[`step${this.currentStep}Group`].$anyError) {
        if (this.currentStep === 2) {
          // Отправка отзыва
          try {
            this.loading = true;
            if (!this.reviewData.price) {
              this.reviewData.price = null;
            }
            // eslint-disable-next-line prefer-destructuring
            if (!this.review) {
              await reviewsApi.create(this.reviewData);
            } else {
              // Редактирование
              await reviewsApi.update(this.reviewData);
            }
            this.complete = true;
          } catch (e) {
            if (e.response.code >= 500) {
              this.showMessage({
                title:
                  'Не удалось отправить отзыв, попробуйте позже или обратитесь в техническую поддержку!',
              });
            } else {
              this.showMessage({
                title:
                  'Не удалось отправить отзыв, проверьте правильность данных или попробуйте переключить дату рекламы / обновить страницу и отправить еще раз!',
              });
            }
          } finally {
            this.loading = false;
          }
          return;
        }
        if (this.currentStep < this.steps) {
          this.currentStep += 1;
        }
      }
    },
    reset() {
      this.$router.push('/reviews/new/');
      this.review = null;
      this.complete = false;
      this.currentStep = 1;
      this.reviewData = {
        customer: null,
        advertiser: null,
        price: null,
        price_meta: null,
        arrival: null,
        paid_off: true,
        date: null,
        customer_kind: 'blogger',
        rate: 10,
        customer_tags: [],
        item: null,
        agree: false,
        ad_type: null,
        text: '',
        screenshots: [],
        coverage: null,
      };
      this.$v.$reset();
    },
    toggleprice_meta(val) {
      this.reviewData.price_meta = this.reviewData.price_meta === val ? null : val;
    },
    handleFile(files) {
      const formData = new FormData();
      for (let k = 0; k < files.length; k += 1) formData.append('files[]', files[k]);
      apiService
        .post('/api/reviews/upload-screenshot/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          // eslint-disable-next-line func-names
          onUploadProgress: function (event) {
            // eslint-disable-next-line radix
            this.fileLoading = parseInt(Math.round((event.loaded / event.total) * 100));
          }.bind(this),
        })
        .then((res) => {
          this.reviewData.screenshots = this.reviewData.screenshots.concat(res);
        })
        .catch((error) => {
          this.fileLoading = false;
        });
    },
  },
  async mounted() {
    if (this.id) {
      try {
        this.review = await reviewsApi.get(this.id); // Загрузка данных из отзыва
        this.reviewData = {
          ...this.reviewData,
          ...this.review,
          date: new Date(moment(this.review.date)),
        };
      } catch (e) {
        this.showMessage({
          title: 'Ошибка!',
          message:
            'Не удается найти отзыв! Возможно, он был удален. Попробуйте перезагрузить страницу или отправьте отзыв заново!',
          icon: 2,
        });
      }
    }

    if (this.advertiser) this.$set(this.reviewData, 'advertiser', this.advertiser);
    if (this.customer) this.reviewData.customer = this.customer;
    if (this.date) this.reviewData.date = this.date;
    if (this.largest_reach) this.reviewData.largest_reach = this.largest_reach;
    if (this.mutual) {
      this.reviewData.customer_kind = 'blogger';
      this.reviewData.price_meta = 'together';
    }
  },
  validations: {
    reviewData: {
      customer: {
        required,
      },
      advertiser: {
        required,
      },
      arrival: {
        required,
      },
      date: {
        required,
      },
      customer_kind: {
        required,
      },
      item: {
        async required() {
          return this.reviewData.customer_kind === 'blogger' ? true : this.reviewData.item;
        },
      },
      agree: { accepted: (x) => x },
      customer_tags: {
        async required() {
          return this.reviewData.customer_kind === 'blogger'
            ? this.reviewData.customer_tags.length > 0
            : true;
        },
      },
      ad_type: { required },
      screenshots: { required },
      price: {
        async required() {
          return (
            ['barter', 'together'].includes(this.reviewData.price_meta) ||
            (this.reviewData.price && this.reviewData.price > 50)
          );
        },
      },
      price_meta: {
        async required() {
          return !!this.reviewData.price || this.reviewData.price_meta;
        },
      },
    },
    step1Group: [
      'reviewData.customer',
      'reviewData.advertiser',
      'reviewData.price',
      'reviewData.price_meta',
      'reviewData.arrival',
      'reviewData.date',
      'reviewData.customer_kind',
    ],
    step2Group: [
      'reviewData.agree',
      'reviewData.ad_type',
      'reviewData.customer_tags',
      'reviewData.screenshots',
    ],
  },
  watch: {
    'reviewData.date': {
      handler(val) {
        console.log(val);
      },
    },
    'reviewData.price_meta': {
      handler(val) {
        console.log(val);
      },
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .multiselect__select {
  height: 34px !important;
  top: 0px !important;
}

::v-deep .multiselect__tag {
  margin-bottom: 0px !important;
}

::v-deep .multiselect__placeholder {
  margin-bottom: 0px !important;
}
::v-deep .multiselect__single {
  display: block ruby;
  margin: auto auto !important;
  line-height: 22px;
  white-space: nowrap !important;
  max-width: 150px;
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
}
::v-deep .multiselect__tags {
  padding: 5px 40px 1px 8px !important;
  border: 1px solid #d5dfe4 !important;
  min-height: 36px !important;
  max-width: 100% !important;
  width: 100% !important;
  min-width: 90px !important;
}
::v-deep .multiselect {
  border-radius: 5px !important;
  width: 100% !important;
  min-height: 36px !important;
  min-width: 90px !important;
}
.rate .row {
  margin-top: 15px !important;
  position: relative;
  width: 100%;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.ad-mode .button {
  width: 50%;
  line-height: 12px;
}

.rate .stripe {
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  top: 9px;
  z-index: 1;
  background-color: #d5dfe4;
}

.rate .stripe--dark {
  background-color: rgb(25, 34, 41);
}

.files-list >>> .file-wrapper:not(:last-child) {
  margin-bottom: 10px;
}

.textarea {
  height: 180px;
}

#success-popup {
  display: flex;
  flex-wrap: wrap;
}
#success-popup .popup {
  margin: auto;
  padding-bottom: 0;
  z-index: 0;
  max-width: 350px;
}
#success-popup .container {
  display: flex;
  padding: 30px 28px;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
#success-popup a {
  color: #000000;
  height: 36px;
  line-height: 16px;
  font-size: 14px;
  border: 1px solid #d5dfe4;
  border-radius: 5px;
  padding: 10px;
}
@media (max-width: 768px) {
  .custom-control {
    padding-left: 1.8rem !important;
  }
  .custom-checkbox .custom-control-label::before,
  .custom-checkbox .custom-control-label::after,
  .settings__row .custom-control-label::after {
    width: 18px;
    height: 18px;
  }
}
.review--succes__content {
  display: flex;
  padding: 0 15px;
  flex-direction: column;
  align-items: center;
}
.ad-wrapper {
  margin-top: 0px;
  margin-bottom: 5px;
}
#success-popup {
  align-items: center;
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  margin-top: 5%;
}
input::placeholder {
  color: gray !important;
}
.cloak {
  position: absolute !important;
}
</style>
